import {replaceWithSpinner} from "./button_spinner";
import {fade_alerts} from "./fade_alerts";

// When the document is loaded do these actions
document.addEventListener('turbolinks:load', function() {

    let form = document.getElementsByClassName("new_user")[0]
    form.addEventListener("submit", replaceWithSpinner)

    fade_alerts()
});