export function fade_alerts() {
    let alert_element = document.getElementById("timer-alert");
    if (alert_element) {
        setTimeout(function () {
            alert_element.style.opacity = '0';
        }, 5000);

        setTimeout(function () {
            alert_element.parentNode.removeChild(alert_element);
        }, 6000);
    }
}