// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import 'core-js/stable'
import 'regenerator-runtime/runtime'

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
require("bootstrap")
require('application/sweet_alert_confirm')
require('src/initializer')
require('src/fade_alerts')

window.addEventListener("load", () => {
    const input_field = document.querySelector(
        "input[data_toggle_visibility]"
    );

    const open_eye = document.querySelector(
        ".eye"
    );

    const closed_eye = document.querySelector(
        ".eye-closed"
    );

    if (open_eye) {
        closed_eye.addEventListener("click", (event) => {
            event.preventDefault();
            console.log("CLICK CLOSED");
            closed_eye.style.display = "none"
            open_eye.style.display = "block"
            const type = input_field.getAttribute("type") === "password" ? "text" : "password";
            input_field.setAttribute("type", type)
        });

        open_eye.addEventListener("click", (event) => {
            event.preventDefault();
            console.log("CLICK OPEN");
            closed_eye.style.display = "block"
            open_eye.style.display = "none"
            const type = input_field.getAttribute("type") === "password" ? "text" : "password";
            input_field.setAttribute("type", type)

        });

    }
});


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
