export function replaceWithSpinner() {

    let loginButton = document.getElementById("disable-on-click-with-spinner")
    let registerButton = document.getElementById("disable-on-click")
    let facebookButton = document.getElementById("disable-on-click-facebook")

    loginButton.innerHTML = "<div class=\"spinner-border text-light small-spinner\" role=\"status\"><span></span></div>"
    loginButton.setAttribute("disabled", true)


    registerButton.setAttribute("disabled", true)
    registerButton.href = "javascript:void(0)"
    registerButton.classList.add("disabled")

    facebookButton.setAttribute("disabled", true)
    facebookButton.href = "javascript:void(0)"
    facebookButton.classList.add("disabled")
}